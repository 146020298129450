import React from "react"
import MarketoForm from "../MarketoForm"

class TwoColumnMarketo extends React.Component {
  constructor(props) {
    super(props)
    this.data = props.data
    this.section = props.data.primary
    this.state = {
      gatesVisible: true,
    }
    this.triggerOnSuccess = this.triggerOnSuccess.bind(this)
  }

  triggerOnSuccess() {
    this.setState({ gatesVisible: false })
  }

  render() {
    return (
      <section id={this.section.section_id} className="uk-section">
        <div className={`uk-container`}>
          <div className="uk-grid-large" data-uk-grid>
            <div className="uk-width-2-3@m">
              {this.section.section_title && <h2>{this.section.section_title.text}</h2>}
              {this.section.content && (
                <div dangerouslySetInnerHTML={{ __html: this.section.content.html }} />
              )}
            </div>
            <div className="uk-width-expand uk-text-center">
              {this.state.gatesVisible && (
                <div className="uk-box-shadow-medium uk-border-rounded uk-padding">
                  {this.section.marketo_form_title && (
                    <h2 className="uk-text-center">{this.section.marketo_form_title.text}</h2>
                  )}
                  <MarketoForm
                    formId={this.section.marketo_form_id ? this.section.marketo_form_id : "1571"}
                    triggerOnSuccess={this.triggerOnSuccess}
                  />
                </div>
              )}
              {!this.state.gatesVisible && (
                <div className="uk-box-shadow-medium uk-border-rounded uk-padding">
                  {this.section.confirmation_message && (
                    <h3 className="uk-text-center">{this.section.confirmation_message.text}</h3>
                  )}
                  {this.section.marketo_url && (
                    <a
                      href={this.section.marketo_url.url}
                      target={this.section.marketo_url.target}
                      className="uk-button uk-button-primary uk-border-pill"
                    >
                      Download
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TwoColumnMarketo
