import React from "react"

const ThreeColumnCards = ({ data, children }) => {
  const section = data.primary

  return (
    <section id={data.id} className="uk-section">
      <div className="uk-container">
        <div
          className={`uk-grid-margin-medium uk-grid-match uk-child-width-1-3@m`}
          data-uk-grid
          data-uk-height-match="target: div > .uk-card > .uk-card-header"
        >
          {children}
        </div>
      </div>
    </section>
  )
}

export default ThreeColumnCards
