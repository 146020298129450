import React from "react"

const OneColumn = ({ data }) => {
  const section = data.primary
  return (
    <section id={section.section_id} className={`uk-section`}>
      <div className="uk-container uk-container-small">
        {section.section_title && (
          <h2 className={data.slice_label === "text-center" ? "uk-text-center" : "uk-text-left"}>
            {section.section_title.text}
          </h2>
        )}
        {section.description && (
          <div
            className={data.slice_label === "text-center" ? "uk-text-center" : "uk-text-left"}
            dangerouslySetInnerHTML={{ __html: section.description.html }}
          />
        )}
        <p className={data.slice_label === "text-center" ? "uk-text-center" : "uk-text-left"}>
          {section.section_button_url && section.section_button_label && (
            <a
              className="uk-button uk-button-primary uk-border-pill"
              href={section.section_button_url.url}
              target={section.section_button_url.target}
            >
              {section.section_button_label}
            </a>
          )}
        </p>
      </div>
    </section>
  )
}

export default OneColumn
