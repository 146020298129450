import React from "react"
import { Image } from "../VindiciaComponents"

const TwoColumnImage = ({ data }) => {
  const section = data.primary

  const collapsible = label => {
    const collapse = {
      collapseStyle: label.includes("collapse")
        ? `uk-position-z-index uk-flex uk-flex-middle uk-padding-small background-pink uk-light`
        : null,
      position: label.includes("collapse") ? { marginLeft: "-200px" } : null,
    }
    return collapse
  }

  return (
    <section
      id={section.section_id}
      className="uk-section"
      style={{ backgroundColor: section.background_color }}
    >
      <div className={`uk-container two-column-with-image`}>
        <div className="uk-grid-large" data-uk-grid>
          <div className="uk-text-center uk-width-2-5@m">
            <Image
              className="uk-position-relative"
              style={data.slice_label && collapsible(data.slice_label).position}
              src={section.image.fixed.src}
              srcSet={section.image.fixed.srcSet}
              alt={section.image.alt}
            />
          </div>
          <div
            className={`uk-width-expand uk-flex uk-flex-middle ${data.slice_label &&
              data.slice_label.includes("image-right") &&
              "uk-flex-first@m"} ${data.slice_label &&
              collapsible(data.slice_label).collapseStyle}`}
          >
            <div>
              {section.section_title && <h2>{section.section_title.text}</h2>}
              {section.text_block && (
                <div dangerouslySetInnerHTML={{ __html: section.text_block.html }} />
              )}
              {section.section_button_url && section.section_button_label && (
                <a
                  className="uk-button uk-button-primary uk-border-pill"
                  href={section.section_button_url.url}
                  target={section.section_button_url.target}
                >
                  {section.section_button_label}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoColumnImage
