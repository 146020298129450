import React from "react"
//import React, {useState, useEffect} from "react";
import { graphql } from "gatsby"
import Hero from "../components/Landings/Hero"
import Layout from "../components/layout"
import OneColumn from "../components/Landings/OneColumn"
//import Marketo from "../components/Landings/Marketo";
import TwoColumnImage from "../components/Landings/TwoColumnImage"
import TwoColumnMarketo from "../components/Landings/TwoColumnMarketo"
import ThreeColumnCards from "../components/Landings/ThreeColumnCards"
import SEO from "../components/seo"
import { withPreview } from "gatsby-source-prismic"
import { Image } from "../components/VindiciaComponents"

const LandindPage = ({ data }) => {
  const sections = data.prismicLandingPage.data
  const page = data.prismicLandingPage.uid

  const contentType = section => {
    let t
    const type = section.slice_type

    switch (type) {
      case "one_column":
        t = <OneColumn key={`section-${section.id}`} data={section} />
        break
      case "two_columns_with_image":
        t = <TwoColumnImage key={`section-${section.id}`} data={section} />
        break
      case "three_columns_cards":
        t = (
          <ThreeColumnCards key={`section-${section.id}`} data={section}>
            {section.items.map((item, i) => (
              <li key={`card-${i}`}>
                <a
                  className="uk-card uk-card-default uk-card-small uk-card-hover uk-box-shadow-medium uk-border-rounded uk-padding"
                  href={item.card_link.url}
                  data-uk-card
                >
                  <div className="uk-card-media-top uk-text-center">
                    <Image
                      src={item.image.fluid.src}
                      srcSet={item.image.fluid.srcSet}
                      alt={item.image.alt}
                    />
                  </div>
                  <div className="uk-card-body uk-text-center">
                    <h4>
                      <strong>{item.card_title.text}</strong>
                    </h4>
                    <div dangerouslySetInnerHTML={{ __html: item.card_text.html }} />
                  </div>
                </a>
              </li>
            ))}
          </ThreeColumnCards>
        )
        break
      case "main_text___marketo":
        t = <TwoColumnMarketo key={`section-${section.id}`} data={section} />
        break
      case "marketo_form":
        //t = <Marketo key={`section-${section.id}`} data={section} />
        break
      default:
        break
    }
    return t
  }

  return (
    <Layout>
      <SEO
        title={sections.seo_title}
        description={sections.seo_description}
        keywords={sections.seo_keywords}
      />
      <div>
        <Hero page={page} data={sections} />
        {sections.body.map((section, i) => contentType(section))}
      </div>
    </Layout>
  )
}

export default withPreview(LandindPage)

export const landingPageQuery = graphql`
  query landingPageQuery($uid: String) {
    prismicLandingPage(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        text_align
        hero_title {
          text
        }
        hero_text
        hero_image {
          alt
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        button_url {
          url
          target
        }
        button_label
        body {
          ... on PrismicLandingPageBodyMarketoForm {
            id
            primary {
              marketo_form_id
              section_anchor
              section_title {
                text
              }
              resource_link {
                link_type
                target
                url
              }
            }
            slice_type
          }
          ... on PrismicLandingPageBodyMainTextMarketo {
            id
            slice_type
            primary {
              confirmation_message {
                text
              }
              content {
                html
              }
              marketo_form_id
              marketo_form_title {
                text
              }
              marketo_url {
                target
                url
              }
              section_title {
                text
              }
            }
          }
          ... on PrismicLandingPageBodyOneColumn {
            id
            slice_type
            slice_label
            primary {
              section_button_label
              section_title {
                text
              }
              section_button_url {
                url
              }
              description {
                html
              }
            }
          }
          ... on PrismicLandingPageBodyThreeColumnsCards {
            id
            slice_type
            items {
              image {
                fluid(maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                alt
              }
              card_text {
                html
              }
              card_title {
                text
              }
              card_link {
                url
              }
            }
            primary {
              show_images_as
            }
          }
          ... on PrismicLandingPageBodyTwoColumnsWithImage {
            id
            slice_label
            slice_type
            primary {
              background_color
              image {
                alt
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              section_button_label
              section_button_url {
                url
              }
              section_title {
                text
              }
              text_block {
                html
              }
            }
          }
        }
      }
    }
  }
`
