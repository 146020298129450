import React from "react"
import { Background } from "../VindiciaComponents"

const Hero = ({ data, ...props }) => {
  const text_align = data.text_align
  const image = data.hero_image.fluid.src

  return (
    <header>
      <div className="uk-light">
        <Background
          src={image}
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: `center center`,
            display: `flex`,
            alignItems: `center`,
            height: "100%",
            width: "100%",
            paddingTop: "100px",
          }}
        >
          <div className={`uk-container uk-flex-auto uk-position-relative`}>
            <div
              className="uk-width-2-5@s"
              data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
              style={{
                margin: text_align === "center" ? "0 auto" : "0 auto 0 0",
                textAlign: text_align === "center" ? "center" : "left",
              }}
            >
              <div>
                <h1>{data.hero_title.text}</h1>
                {data.hero_text && <p className="uk-margin-remove-top">{data.hero_text}</p>}
              </div>
            </div>
          </div>
        </Background>
      </div>
    </header>
  )
}

export default Hero
